import React, { useState, useRef } from "react";

import "bulma/css/bulma.min.css";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

const allJobExamples = [
  "lawyer",
  "nurse",
  "teacher",
  "student",
  "doctor",
  "engineer",
  "accountant",
  "programmer",

  "Web developer",
  "Frontend developer",
  "Data scientist",
  "Product manager",
  "UX designer",
];

var jobTitles = [
  "Web developer",
  "Frontend developer",
  "Data scientist",
  "Product manager",
  "UX designer",
  "Assembly line worker",
  "Tesla  factory worker",
  "Amazon warehouse worker",
  "Bomb defuser",
  "Front line soldier",
  "Prisan guard",
  "Detective",

  "Uber driver",
  "City bus driver",
  "Airbnb host",
  "Slumlord",
  "Pimp",
  // "Gigolo",
  "Hitman",
  "Cowboy",
  "Digital nomad",
  "Kidnaper",
  //"Pickpocket",

  "Sceptic tank management agent",
  "Copywriter",

  "Influencer",
  "Youtuber",
  "Tiktok star",
  "Instagram model",
  "Twitch streamer",

  "Barista",
  "Cashier",
  "Customer support",

  "Divorce attorney",
  "Couple therapist",
  "Criminal lawyer",
  "Life coach",
  "Personal trainer",
  "OnlyFans model",
  "Venture capitalist",
  "Real estate agent",
  "Stock broker",
  "Financial advisor",
  "Insurance agent",
  // "Loan officer",
  "Tax accountant",
  "Bookkeeper",
  "Receptionist",

  "Doctor",
  "Lawyer",
  "Engineer",
  "Teacher",
  "Nurse",
  "Police Officer",
  "Firefighter",
  "Accountant",
  "Marketing Manager",
  "IT Professional",
  "Human Resources Manager",
  "Web Developer",
  "Graphic Designer",
  "Journalist",
  "Public Relations Specialist",
  "Environmental Scientist",
  "Zoologist",
  "Geologist",
  "Astronomer",
  "Chef",
  "Bartender",
  "Florist",
  "Interior Designer",
  "Event Planner",
  "Hairdresser",
  "Makeup Artist",
  "Music Producer",
  "Music Composer",
  "Photo editor",
  "Actor",
  "Comedian",
  "Clown",
  "Magician",
  "Circus Performer",
  "Stunt Double",
  "Professional Gamer",
  "Athlete",
  "Coach",
  "Referee",
  "Sports Commentator",
  "Race Car Driver",
  "Pilot",
  "Flight Attendant",
  "Travel Agent",
  "Tour Guide",
  "Park Ranger",
  "Zoo Keeper",
  "Wildlife Biologist",
  "Marine Biologist",
  "Fisherman",
  "Farmer",
  "Rancher",
  "Logger",
  "Miner",
  "Oil Rig Worker",
  "Construction Worker",
];

function pickEx() {
  var tt = jobTitles.sort(() => Math.random() - 0.5);
  return tt.slice(0, 36);
}

var jobExamples = pickEx();

function App() {
  const [textValue, setTextValue] = useState("");
  const [apiResult, setApiResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handleClose = () => {
    setApiResult(null);
  };

  const handleSubmit = async (event, directTxt) => {
    event.preventDefault();
    setIsLoading(true);
    jobExamples = pickEx();
    //alert(textValue);
    var job = directTxt || textValue;
    //console.log("submit", textValue);
    if (!job) {
      setIsLoading(false);
      return;
    }
    var server = "https://gpt.mid.run/advice/";
    // server = "https://6aa5-186-99-139-197.ngrok.io/advice/";
    const response = await fetch(`${server}${job}`, {
      // mode: "no-cors",
    });
    //console.log(response, 5843223211111448);
    //const data = await response.json();
    const data = await response.text();
    if (data.error) {
      console.log(data.error);
      setIsLoading(false);
      return;
    }
    //console.log(data, 58448);

    let title = "";
    let description = "";

    const firstPunctuationIndex = data.search(/[\.\!\?]/);
    if (firstPunctuationIndex !== -1) {
      title = data.substring(0, firstPunctuationIndex + 1);
      description = data.substring(firstPunctuationIndex + 1);
    } else {
      title = "Ok!";
      description = data;
    }

    setApiResult({ title, description });
    setIsLoading(false);
  };

  return (
    <div className="section">
      <div className="container" style={{ maxWidth: "500px" }}>
        {!apiResult && (
          <span>
            <h1 className="title is-3">Will you keep your job? </h1>
            <h2 className="subtitle is-5">
              Ask the AI for honest career advice.
            </h2>
            <br />
          </span>
        )}
        <div className="columns is-mobile">
          <div className="column  ">
            <form ref={formRef} onSubmit={handleSubmit}>
              {apiResult && (
                <div
                  style={{ opacity: isLoading ? 0.3 : 1 }}
                  className="notification is-link is-light"
                >
                  <button className="delete" onClick={handleClose}></button>

                  <h2 className="title is-4">{apiResult.title}</h2>
                  <p>{apiResult.description}</p>

                  <small>
                    <br />— AI career counsellor - via{" "}
                    <a href="https://advice.mid.run">
                      <em style={{ textDecoration: "underline" }}>
                        advice.mid.run
                      </em>
                    </a>
                  </small>
                </div>
              )}
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={textValue}
                    disabled={isLoading}
                    onChange={handleTextChange}
                    placeholder="Copywriter, Cop, Clown, Programmer, Student, etc."
                  />
                </div>
              </div>
              <div className="columns is-mobile is-vcentered">
                <div className="column is-narrow">
                  <div className="field">
                    <div className="control">
                      <button
                        disabled={isLoading}
                        className={`button is-link ${
                          isLoading ? "is-loading" : ""
                        }`}
                        type="submit"
                      >
                        Ask AI
                      </button>
                    </div>
                  </div>
                </div>
                <div className="column"></div>
                {apiResult && (
                  <div className="column is-narrow">
                    <small style={{ opacity: 0.7 }}>
                      ⚡ App built in ~1 hour using{" "}
                      <strong>
                        <a href="https://mid.run">mid.run</a>{" "}
                      </strong>{" "}
                      <br />
                    </small>
                  </div>
                )}
              </div>
            </form>
            <br /> <hr />
            <br />
            <h2 className="title is-5">Trending searches</h2>
            <h2 className="subtitle is-6">
              Are these jobs doomed? Or a safe bet for the future?
            </h2>
            <div className="job-examples">
              <ul className="columns is-multiline is-mobile">
                {jobExamples.map((example) => (
                  <li
                    className="column is-one-third is-half-mobile"
                    key={example}
                  >
                    <a
                      key={example}
                      className="job-example    is-light is-small"
                      onClick={(event) => {
                        event.preventDefault();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setTextValue(example);
                        handleSubmit(new Event("submit"), example);
                        // formRef.current.dispatchEvent(new Event("submit"));
                        // Add your API call here
                      }}
                      href="#"
                    >
                      {example}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>{" "}
        </div>
        <hr />
        <div className="share-buttons">
          <FacebookShareButton
            url="https://advice.mid.run"
            quote="Will you keep your job? Ask the AI for honest career advice."
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url="https://advice.mid.run"
            title="Will you keep your job?  "
            quote="Will you keep your job? Ask the AI for honest career advice."
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <RedditShareButton
            url="https://advice.mid.run"
            quote="Will you keep your job? Ask the AI for honest career advice."
          >
            <RedditIcon size={32} round={true} />
          </RedditShareButton>

          <EmailShareButton
            url="https://advice.mid.run"
            body="Will you keep your job? Ask the AI for honest career advice."
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>

          <LinkedinShareButton
            url="https://advice.mid.run"
            body="Will you keep your job? Ask the AI for honest career advice."
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
        </div>{" "}
        <hr />
        <small>
          <strong>About</strong>. This app is just for fun. It's been built
          quickly using <a href="https://mid.run">mid.run</a> to showcase it's
          feature. Midrun is a new full-stack toolkit to quickly build modern
          APIs.
          <p>
            The front-end code has been drafted by AI (chatGpt) around React,
            Bulma, and Fetch. If you are a developer interested in APIs and AI,
            email us at team-AT-mid.run or{" "}
            <a href="https://mid.run">sign up for the Midrun beta</a>. ⚡
          </p>
        </small>
      </div>
    </div>
  );
}

export default App;
